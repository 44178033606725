import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Observable } from 'rxjs';
import { SessionService } from '../shared/services/session.service';
import { async } from '@angular/core/testing';



@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

	currentURL: string;
	constructor(private session: SessionService) {

	}
	LoginStatus$: Observable<boolean>;
	userType$: Observable<string>;

	logout() {
		this.session.logout();
	}
	ngOnInit(): void {

		this.LoginStatus$ = this.session.isLoggesIn;
		this.userType$ = this.session.currentUserType;
		
		$(function () {
			var header = $(".start-style");
			$(window).scroll(function () {
				var scroll = $(window).scrollTop();

				if (scroll >= 10) {
					header.removeClass('start-style').addClass("scroll-on");
				} else {
					header.removeClass("scroll-on").addClass('start-style');
				}
			});
		});

		//Animation

		/*$(document).ready(function () {
			$('header.hero-anime').removeClass('hero-anime');
		});

		//Menu On Hover

		$('header').on('mouseenter mouseleave', '.nav-item', function (e) {
			if ($(window).width() > 750) {
				var _d = $(e.target).closest('.nav-item'); _d.addClass('show');
				setTimeout(function () {
					_d[_d.is(':hover') ? 'addClass' : 'removeClass']('show');
				}, 1);
			}
		});*/

	}
}
