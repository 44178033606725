import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../shared/services/session.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {



  constructor(private router: Router, private session: SessionService,) {
  }

  userType$: Observable<string>;

  logout() {
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    this.session.logout();
  }

  ngOnInit(): void {

    this.userType$ = this.session.currentUserType;

    $("#sidenavToggler").click(function (e) {
      e.preventDefault();
      $("#page-top").toggleClass("sidenav-toggled");
      $(".navbar-sidenav .nav-link-collapse").addClass("collapsed");
      $(".navbar-sidenav .sidenav-second-level, .navbar-sidenav .sidenav-third-level").removeClass("show");
    });

  }

}
