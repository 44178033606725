<!--<div class="my-2 py-2" >
</div>-->
<div class="footer">
	<footer class="footer-distributed">

		<div class="footer-left">

			<h3>Smart<span>Champs</span></h3>

			<p class="footer-links">
				<a routerLink="/home/banner" class="link-1">Home</a>

				<a routerLink="/home/portfolio">Portfolio</a>

				<a routerLink="/home/smartogames">Smarto Games</a>

				<a routerLink="/home/services">Services</a>

				<a routerLink="/home/contact">Contact</a>

				<a routerLink="/auth/login">Login</a>
			</p>

			<span class="footer-left-owner"> Aruman Creations, C/O Ravindra Naik </span>
		</div>

		<div class="footer-center">

			<div>
				<i class="fa fa-map-marker"></i>
				<p>
					<span>Ranade Bungalow, Prabhat Road</span>
					<span>Pune-04, India.</span>
				</p>
			</div>

			<div>
				<i class="fa fa-phone"></i>
				<p>+91 7798156655</p>
			</div>

			<div>
				<i class="fa fa-envelope"></i>
				<p>smartchampspune@gmail.com</p>
			</div>

		</div>

		<div class="footer-right">

			<p class="footer-company-about">
				<span class="footer-company-about-title">About the company</span>
				<span class="footer-company-about-dec">Nothing is more important than raising intelligent, confident &
					creative children. Anything and everything to make children's life simple, because "to be simple is
					more difficult" </span>
			</p>

			<div class="footer-icons">

				<a href="#"><i class="fa fa-facebook"></i></a>
				<a href="#"><i class="fa fa-twitter"></i></a>
				<a href="#"><i class="fa fa-instagram"></i></a>
				<a href="#"><i class="fa fa-youtube"></i></a>

			</div>

		</div>

	</footer>
	<div class="footer-copyrights">
		Copyright © 2020 SmartChamps. All Rights Reserved.
	</div>
</div>