import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alluser',
  templateUrl: './alluser.component.html',
  styleUrls: ['./alluser.component.css']
})
export class AlluserComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
