import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    $( document ).ready(function() {
  		$(window).scroll(function() {  	
    		if($(document).scrollTop() > 100) {    
      			$('.footer').addClass("show");
    		}
    		else {
      			$('.footer').removeClass("show");
    		}
  		});
	  });
  }

}
