<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

<header class="hero-anime">

	<div class="navigation-wrap bg-light start-header start-style">
		<div class="navigation-wrap-bg-color">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<nav class="navbar navbar-expand-md navbar-light navbar-no-spacing">

							<a class="navbar-brand navbar-brand-no-spacing" routerLink="/home/banner" target="_blank"><img
									src="../../../../../assets/img/logo.png" alt=""></a>

							<button class="navbar-toggler" type="button" data-toggle="collapse"
								data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
								aria-expanded="false" aria-label="Toggle navigation">
								<span class="navbar-toggler-icon"></span>
							</button>

							<div class="collapse navbar-collapse" id="navbarSupportedContent">
								<ul class="navbar-nav ml-auto py-4 py-md-0">
									<!--<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4 active">
									<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Home</a>
									<div class="dropdown-menu">
										<a class="dropdown-item dropdown-item-hover" href="#">Action 1</a>
										<a class="dropdown-item dropdown-item-hover" href="#">Action 2</a>
									</div>
								</li>-->
									<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
										<a class="nav-link" routerLink="/home/banner">Home</a>
									</li>
									<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
										<a class="nav-link" routerLink="/home/portfolio">Portfolio</a>
									</li>
									<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
										<a class="nav-link" routerLink="/home/smartogames">Smarto Games</a>
									</li>
									<!--<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
										<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#"
											role="button" aria-haspopup="true" aria-expanded="false">Services</a>
										<div class="dropdown-menu">
											<a class="dropdown-item dropdown-item-hover" href="#">Action 1</a>
											<a class="dropdown-item dropdown-item-hover" href="#">Action 2</a>
										</div>
									</li>
									<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
										<a class="nav-link" routerLink="/home/services">Services</a>
									</li>-->
									<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
										<a class="nav-link" routerLink="/home/contact">Contact</a>
									</li>
									<li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
										<a *ngIf="!(LoginStatus$ | async) as LoginStatus" class="nav-link"
											routerLink="/auth/login">Login</a>
										<a *ngIf="(LoginStatus$ | async) as LoginStatus"
											class="nav-link dropdown-toggle" data-toggle="dropdown" role="button"
											aria-haspopup="true" aria-expanded="false"><i class="fa fa-user"
												style="font-size: 20px;"></i></a>
										<div *ngIf="(LoginStatus$ | async) as LoginStatus" class="dropdown-menu">
											<a *ngIf="(userType$ | async) === 'user'"
												class="dropdown-item dropdown-item-hover"
												routerLink="/user/dashboard">Dashboard</a>
											<a *ngIf="(userType$ | async) === 'admin'"
												class="dropdown-item dropdown-item-hover"
												routerLink="/admin/dashboard">Dashboard</a>
											<a *ngIf="(userType$ | async) === 'super admin'"
												class="dropdown-item dropdown-item-hover"
												routerLink="/superadmin/dashboard">Dashboard</a>
											<a class="dropdown-item dropdown-item-hover" (click)="logout()">Logout</a>
										</div>
									</li>
								</ul>
							</div>

						</nav>
					</div>
				</div>


			</div>
		</div>
	</div>
	<!--<div *ngIf="currentURL === '/home/home'" class="section full-height">
		<div class="absolute-center">
			<div class="section">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<h1><span>B</span><span>o</span><span>o</span><span>t</span><span>s</span><span>t</span><span>r</span><span>a</span><span>p</span>
								<span>4</span><br>
								<span>m</span><span>e</span><span>n</span><span>u</span></h1>
							<p>scroll for nav animation</p>
						</div>
					</div>
				</div>
			</div>
			<div class="section mt-5">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<div id="switch">
								<div id="circle"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>-->
	<!--<div class="my-4 py-4">
	</div>-->

	<!-- Link to page
================================================== -->

	<!-- <a href="https://front.codes/" class="logo" target="_blank">
		<img src="" alt="">
	</a> -->

</header>
<!--<div class="my-4 py-4">
</div>-->